// store/index.js
import { createStore as createVuexStore } from 'vuex'

// Define initial state factory to ensure fresh state for each request
const getInitialState = () => ({
    isLoggedIn: false,
    userRole: null,
    intendedRoute: null
})

// Create store factory function
export function createStore() {
    return createVuexStore({
        state: getInitialState(),

        mutations: {
            SET_LOGGED_IN(state, payload) {
                state.isLoggedIn = payload
            },
            SET_USER_ROLE(state, role) {
                state.userRole = role
            },
            setIntendedRoute(state, route) {
                state.intendedRoute = route
            },
            // Reset state mutation for cleanup
            RESET_STATE(state) {
                Object.assign(state, getInitialState())
            }
        },

        actions: {
            // Initialize store with SSR data if available
            initializeStore({ state }) {
                if (typeof window !== 'undefined' && window.__INITIAL_STATE__) {
                    Object.assign(state, window.__INITIAL_STATE__)
                }
            },

            login({ commit }, userData) {
                return new Promise((resolve) => {
                    try {
                        commit('SET_LOGGED_IN', true)
                        commit('SET_USER_ROLE', userData.id_role)
                        
                        // Handle client-side storage if in browser
                        if (typeof window !== 'undefined') {
                            localStorage.setItem('isLoggedIn', 'true')
                            localStorage.setItem('userRole', userData.id_role)
                        }
                        
                        resolve(true)
                    } catch (error) {
                        console.error('Login error:', error)
                        resolve(false)
                    }
                })
            },

            logout({ commit }) {
                return new Promise((resolve) => {
                    try {
                        commit('SET_LOGGED_IN', false)
                        commit('SET_USER_ROLE', null)
                        
                        // Handle client-side storage if in browser
                        if (typeof window !== 'undefined') {
                            localStorage.removeItem('isLoggedIn')
                            localStorage.removeItem('userRole')
                        }
                        
                        resolve(true)
                    } catch (error) {
                        console.error('Logout error:', error)
                        resolve(false)
                    }
                })
            },

            // Restore auth state from localStorage on client-side
            restoreAuthState({ commit }) {
                if (typeof window !== 'undefined') {
                    const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true'
                    const userRole = localStorage.getItem('userRole')
                    
                    if (isLoggedIn && userRole) {
                        commit('SET_LOGGED_IN', true)
                        commit('SET_USER_ROLE', userRole)
                    }
                }
            }
        },

        getters: {
            isLoggedIn: state => state.isLoggedIn,
            userRole: state => state.userRole,
            loggedInUserRole: state => state.userRole,
            intendedRoute: state => state.intendedRoute
        },

        // Enable strict mode in development
        strict: process.env.NODE_ENV !== 'production'
    })
}

// Create a store instance for client-side use
let store

// Get store instance (singleton pattern for client-side)
export function useStore() {
    if (typeof window !== 'undefined' && !store) {
        store = createStore()
        
        // Initialize store on client-side
        store.dispatch('initializeStore')
        store.dispatch('restoreAuthState')
    }
    return store
}

// Export default store instance
export default useStore()