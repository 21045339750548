
// src/mixins/metaMixin.js

// MetaHelpers object for utility functions
const MetaHelpers = {
  // Core meta tag manipulation functions
  setMetaTag(attributeName, name, content) {
    if (!content) return;
    try {
      let meta = document.querySelector(`meta[${attributeName}="${name}"]`);
      if (!meta) {
        meta = document.createElement('meta');
        meta.setAttribute(attributeName, name);
        document.head.appendChild(meta);
      }
      meta.setAttribute('content', this.sanitizeContent(content));
    } catch (error) {
      console.error(`Error setting meta tag ${name}:`, error);
    }
  },

  getFullImageUrl(imagePath, baseUrl, defaultImage) {
    try {
      if (!imagePath) return `${baseUrl}${defaultImage}`;
      return imagePath.startsWith('http') ? imagePath : `${baseUrl}${imagePath}`;
    } catch (error) {
      console.error('Error getting full image URL:', error);
      return `${baseUrl}${defaultImage}`;
    }
  },

  setCanonicalUrl(url) {
    if (!url) return;
    try {
      const cleanUrl = url.replace(/\/+$/, '').split('?')[0];
      let canonical = document.querySelector('link[rel="canonical"]');
      if (!canonical) {
        canonical = document.createElement('link');
        canonical.setAttribute('rel', 'canonical');
        document.head.appendChild(canonical);
      }
      canonical.setAttribute('href', cleanUrl);
      this.setMetaTag('property', 'og:url', cleanUrl);
    } catch (error) {
      console.error('Error setting canonical URL:', error);
    }
  },

  // Content formatting and sanitization
  formatContent(content) {
    if (!content) return '';
    try {
      return content
        .replace(/<[^>]*>/g, '') // Remove HTML tags
        .replace(/&\w+;/g, ' ')  // Replace HTML entities with space
        .replace(/[^\w\s-]/g, '') // Remove special characters
        .replace(/\s+/g, ' ')    // Replace multiple spaces with single space
        .trim();
    } catch (error) {
      console.error('Error formatting content:', error);
      return '';
    }
  },

  sanitizeContent(content) {
    if (!content) return '';
    try {
      return content
        .replace(/["'<>]/g, '')  // Remove quotes and angle brackets
        .replace(/&/g, '&amp;')  // Escape ampersands
        .trim();
    } catch (error) {
      console.error('Error sanitizing content:', error);
      return '';
    }
  },

  createSlug(text, options = {}) {
    if (!text) return '';
    try {
      const {
        maxLength = 60,
        separator = '-'
      } = options;

      return this.formatContent(text)
        .toLowerCase()
        .replace(/\s+/g, separator)
        .substr(0, maxLength)
        .replace(new RegExp(`${separator}+$`), '');
    } catch (error) {
      console.error('Error creating slug:', error);
      return '';
    }
  },

  cleanupMetaTags() {
    try {
      // Remove duplicate meta tags
      const seen = new Set();
      document.querySelectorAll('meta').forEach(meta => {
        const name = meta.getAttribute('name') || meta.getAttribute('property');
        if (name) {
          if (seen.has(name)) {
            meta.remove();
          } else {
            seen.add(name);
          }
        }
      });

      // Remove empty meta tags
      document.querySelectorAll('meta').forEach(meta => {
        const content = meta.getAttribute('content');
        if (!content || content.trim() === '') {
          meta.remove();
        }
      });
    } catch (error) {
      console.error('Error cleaning up meta tags:', error);
    }
  }
};

// Main mixin export
export const metaMixin = {
  data() {
    return {
      baseUrl: 'http://192.168.12.117:8080',
      defaultImage: '/assets/img/business-4/slider/default-banner.webp',
      imageSize: {
        width: 1200,
        height: 630
      },
      socialLinks: {
        facebook: 'https://www.facebook.com/puracigarettesolutions',
        instagram: 'https://www.instagram.com/pura.mics/',
        youtube: 'https://www.youtube.com/channel/UC_1YqET6QtMRvh7IlD-TJYg',
        tiktok: 'https://www.tiktok.com/@puracigarette',
        linkedin: 'https://www.linkedin.com/company/puracigarette/'
      },
      nofollowPaths: [
        '/loginds', '/dashboard', '/DashboardDs',
        '/EditArtikel', '/EditDetailProduk', '/EditKategori',
        '/EditProduk', '/EditPromo', '/EditSosmed',
        '/EditUltah', '/RegisterDs', '/TambahArtikel',
        '/TambahDetailProduk', '/TambahKategori', '/TambahProduk',
        '/TambahSosmed', '/TampilanArtikel', '/TampilanEtiket',
        '/TampilanCp', '/TampilanCtp', '/TampilanCustomer',
        '/TampilanDetailProduk', '/TampilanFilter', '/TampilanKategori',
        '/TampilanPromo', '/TampilanQuestion', '/TampilanSosmed',
        '/TampilanUsers', '/UlangTahun', '/api',
        '/assets', '/js', '/css', '/routes', '/backend',
        '/404', '/error', '/not-found', '/maintenance',
        '/coming-soon', '/under-construction', '/temp',
        '/temporary'
      ],
      allowedPaths: [
        '/', '/AboutPage', '/BlogPura', '/ContactUs',
        '/CustomerPage', '/ProdukPage', '/SamplePage',
        '/SimulasiCp', '/SimulasiCtp', '/DetailBlog',
        '/DetailProduk'
      ],
      defaultDescription: 'Leading provider of innovative cigarette and tobacco solutions.',
      defaultKeywords: 'tobacco solutions, cigarette manufacturing, tobacco industry'
    };
  },

  methods: {
    getFullImageUrl(imagePath) {
      if (!imagePath) return `${this.baseUrl}${this.defaultImage}`;
      return imagePath.startsWith('http') ? imagePath : `${this.baseUrl}${imagePath}`;
    },

    formatPageTitle(title, siteName = 'Pura MICS') {
      try {
        return title ? `${title} | ${siteName}` : siteName;
      } catch (error) {
        console.error('Error formatting page title:', error);
        return siteName;
      }
    },

    shouldAllowRobots(path) {
      const isNofollow = this.nofollowPaths.some(nofollowPath => 
        path.startsWith(nofollowPath) ||
        path.includes('/edit/') ||
        path.includes('/add/') ||
        path.includes('/delete/') ||
        path.includes('/update/') ||
        path.includes('/manage/') ||
        path.includes('/admin/') ||
        path.includes('/dashboard/') ||
        path.includes('/login/') ||
        path.includes('/register/')
      );

      if (isNofollow) return false;

      return this.allowedPaths.some(allowedPath => 
        path === allowedPath || 
        (path.startsWith('/DetailBlog/') && path.includes('/')) ||
        (path.startsWith('/DetailProduk/') && path.includes('/'))
      );
    },

    updateMetaTags(metaInfo) {
      if (!metaInfo) return;
      
      const fullImageUrl = MetaHelpers.getFullImageUrl(metaInfo.image, this.baseUrl, this.defaultImage);
      const currentPath = this.$route?.path || '';
      
      // Special case for BlogPura
      if (this.$route?.path === '/BlogPura') {
        // Force the exact meta information for BlogPura page
        const blogMetaInfo = {
          title: 'Pura MICS Blog - Tobacco Industry News, Innovations & Insights',
          description: 'Stay updated with the latest tobacco industry news, product innovations, manufacturing insights, and events from Pura MICS.',
          keywords: [
            // English Keywords
            'tobacco industry news', 'cigarette manufacturing updates',
            'tobacco product innovations', 'industry best practices',
            'manufacturing standards',
            // Indonesian Keywords
            'berita industri rokok', 'inovasi produk rokok',
            'perkembangan industri rokok', 'standar produksi rokok',
            'teknologi rokok terbaru', 'tren industri rokok',
            'update manufaktur rokok', 'artikel industri rokok'
          ].join(', '),
          image: '/assets/img/business-4/slider/produkk.webp',
          type: 'website',
          robotsDirectives: 'index, follow, max-snippet:-1, max-image-preview:large'
        };
        
        // Set document title directly
        document.title = blogMetaInfo.title;
        
        // Set meta tags directly
        MetaHelpers.setMetaTag('name', 'description', blogMetaInfo.description);
        MetaHelpers.setMetaTag('name', 'keywords', blogMetaInfo.keywords);
        MetaHelpers.setMetaTag('name', 'author', 'Pura MICS');
        MetaHelpers.setMetaTag('name', 'robots', blogMetaInfo.robotsDirectives);
        MetaHelpers.setMetaTag('name', 'googlebot', blogMetaInfo.robotsDirectives);
        
        // Open Graph tags
        MetaHelpers.setMetaTag('property', 'og:title', blogMetaInfo.title);
        MetaHelpers.setMetaTag('property', 'og:description', blogMetaInfo.description);
        MetaHelpers.setMetaTag('property', 'og:image', `${this.baseUrl}${blogMetaInfo.image}`);
        MetaHelpers.setMetaTag('property', 'og:url', `${this.baseUrl}${currentPath}`);
        MetaHelpers.setMetaTag('property', 'og:type', blogMetaInfo.type);
        MetaHelpers.setMetaTag('property', 'og:site_name', 'Pura MICS');
        
        // Twitter Card tags
        MetaHelpers.setMetaTag('name', 'twitter:card', 'summary_large_image');
        MetaHelpers.setMetaTag('name', 'twitter:title', blogMetaInfo.title);
        MetaHelpers.setMetaTag('name', 'twitter:description', blogMetaInfo.description);
        MetaHelpers.setMetaTag('name', 'twitter:image', `${this.baseUrl}${blogMetaInfo.image}`);
        
        // Update canonical URL
        MetaHelpers.setCanonicalUrl(`${this.baseUrl}${currentPath}`);
        
        // Clean up meta tags
        MetaHelpers.cleanupMetaTags();
        
        return; // Exit early
      }
      
      // Special case for ContactUs
      if (this.$route?.path === '/ContactUs') {
        // Force the exact meta information for ContactUs page
        const contactMetaInfo = {
          title: 'Contact Pura MICS - Get in Touch With Us',
          description: 'Contact Pura MICS for innovative cigarette solutions. Reach out to our team for inquiries about our products and services.',
          keywords: [
            // English Keywords
            'pura mics contact', 'cigarette manufacturer contact',
            'tobacco solutions contact', 'manufacturing consultation',
            'business collaboration',
            // Indonesian Keywords
            'kontak produsen rokok', 'konsultasi produk rokok',
            'kerjasama industri rokok', 'layanan konsumen rokok',
            'konsultasi material rokok', 'supplier bahan rokok',
            'distributor produk rokok', 'mitra bisnis rokok'
          ].join(', '),
          type: 'website',
          robotsDirectives: 'index, follow, noarchive'
        };
        
        // Set document title directly
        document.title = contactMetaInfo.title;
        
        // Set meta tags directly
        MetaHelpers.setMetaTag('name', 'description', contactMetaInfo.description);
        MetaHelpers.setMetaTag('name', 'keywords', contactMetaInfo.keywords);
        MetaHelpers.setMetaTag('name', 'author', 'Pura MICS');
        MetaHelpers.setMetaTag('name', 'robots', contactMetaInfo.robotsDirectives);
        MetaHelpers.setMetaTag('name', 'googlebot', contactMetaInfo.robotsDirectives);
        
        // Open Graph tags
        MetaHelpers.setMetaTag('property', 'og:title', contactMetaInfo.title);
        MetaHelpers.setMetaTag('property', 'og:description', contactMetaInfo.description);
        MetaHelpers.setMetaTag('property', 'og:url', `${this.baseUrl}${currentPath}`);
        MetaHelpers.setMetaTag('property', 'og:type', contactMetaInfo.type);
        MetaHelpers.setMetaTag('property', 'og:site_name', 'Pura MICS');
        
        // Twitter Card tags
        MetaHelpers.setMetaTag('name', 'twitter:card', 'summary');
        MetaHelpers.setMetaTag('name', 'twitter:title', contactMetaInfo.title);
        MetaHelpers.setMetaTag('name', 'twitter:description', contactMetaInfo.description);
        
        // Update canonical URL
        MetaHelpers.setCanonicalUrl(`${this.baseUrl}${currentPath}`);
        
        // Clean up meta tags
        MetaHelpers.cleanupMetaTags();
        
        return; // Exit early
      }
      
      // For non-special pages, continue with normal flow
      // Basic meta tags
      MetaHelpers.setMetaTag('name', 'description', metaInfo.description);
      MetaHelpers.setMetaTag('name', 'keywords', metaInfo.keywords);
      MetaHelpers.setMetaTag('name', 'author', 'Pura MICS');

      // Robots directive
      const robotsDirective = this.getRobotsDirective(metaInfo);
      MetaHelpers.setMetaTag('name', 'robots', robotsDirective);
      MetaHelpers.setMetaTag('name', 'googlebot', robotsDirective);

      // Open Graph tags
      MetaHelpers.setMetaTag('property', 'og:title', metaInfo.title);
      MetaHelpers.setMetaTag('property', 'og:description', metaInfo.description);
      MetaHelpers.setMetaTag('property', 'og:image', fullImageUrl);
      MetaHelpers.setMetaTag('property', 'og:url', metaInfo.url || `${this.baseUrl}${currentPath}`);
      MetaHelpers.setMetaTag('property', 'og:type', metaInfo.type || 'website');
      MetaHelpers.setMetaTag('property', 'og:site_name', 'Pura MICS');

      // Twitter Card tags
      MetaHelpers.setMetaTag('name', 'twitter:card', 'summary_large_image');
      MetaHelpers.setMetaTag('name', 'twitter:title', metaInfo.title);
      MetaHelpers.setMetaTag('name', 'twitter:description', metaInfo.description);
      MetaHelpers.setMetaTag('name', 'twitter:image', fullImageUrl);

      // Update canonical URL
      if (metaInfo.canonical) {
        MetaHelpers.setCanonicalUrl(metaInfo.canonical);
      }

      // Update document title
      document.title = this.formatPageTitle(metaInfo.title);

      // Clean up meta tags
      MetaHelpers.cleanupMetaTags();
    },

    getRobotsDirective(metaInfo) {
      const currentPath = this.$route?.path || '';
      
      if (!this.shouldAllowRobots(currentPath)) {
        return 'noindex, nofollow';
      }

      switch (metaInfo.type) {
        case 'product':
          return 'index, nofollow, max-snippet:-1, max-image-preview:large';
        case 'article':
          return 'index, nofollow, max-snippet:-1, max-image-preview:large';
        default:
          return 'index, nofollow';
      }
    },

    getMetaConfig() {
      const configs = {
        home: {
          title: 'Pura Material Innovations and Cigarette Solutions (MICS) - Home',
          description: 'PURA GROUP produces various special products to support the tobacco industry, including Homogenized Tobacco Leaf Wrapper, Cigarette Tipping Paper, Colored Cigarette Paper, and Cigarette Packs.',
          keywords: [
            // English Keywords
            'pura mics', 'cigarette solutions', 'pura group', 'packaging material',
            'tobacco industry solutions', 'cigarette manufacturing',
            'homogenized tobacco leaf', 'tipping paper', 'cigarette components',
            // Indonesian Keywords
            'produk rokok', 'pabrik rokok', 'industri rokok indonesia',
            'kertas rokok', 'filter rokok', 'kemasan rokok',
            'bahan baku rokok', 'pembungkus rokok', 'produsen rokok',
            'kertas tipping rokok', 'mesin rokok', 'perlengkapan rokok',
            'solusi industri rokok', 'produsen kertas rokok',
            'kemasan rokok premium', 'supplier rokok', 'distributor rokok',
            'percetakan kemasan rokok', 'material pendukung rokok',
            'industri kretek', 'rokok kretek', 'kota kretek'
          ].join(', '),
          image: '/assets/img/business-4/slider/LANDINGPAGE.webp',
          imageAlt: 'Pura MICS Home Banner',
          type: 'website',
          url: this.baseUrl,
          canonical: this.baseUrl,
          robotsDirectives: 'index, follow, max-snippet:-1, max-image-preview:large'
        },
        about: {
          title: 'About Pura MICS - A GUIDING LIGHT IN THE TOBACCO INDUSTRY',
          description: 'Learn about Pura MICS, a leading innovator in cigarette solutions. Discover our history, values, and commitment to quality in the tobacco industry.',
          keywords: [
            // English Keywords
            'pura mics', 'pura group subsidiary', 'indonesian cigarette manufacturer',
            'tobacco manufacturing solutions', 'cigarette industry experience',
            'quality manufacturing standards', 'sustainable tobacco solutions',
            // Indonesian Keywords
            'perusahaan rokok indonesia', 'produsen rokok terkemuka',
            'inovasi industri rokok', 'standar kualitas rokok',
            'pengembangan produk rokok', 'solusi manufaktur rokok',
            'pelopor industri rokok', 'ahli pembuatan rokok',
            'teknologi pembuatan rokok', 'riset pengembangan rokok',
            'konsultan industri rokok', 'pengolahan tembakau modern'
          ].join(', '),
          image: '/assets/img/business-4/slider/about.webp',
          imageAlt: 'Pura MICS About Banner',
          type: 'website',
          url: `${this.baseUrl}/AboutPage`,
          canonical: `${this.baseUrl}/AboutPage`,
          robotsDirectives: 'index, follow, max-snippet:-1'
        },
        product: {
          title: 'Pura MICS Products - Innovative Cigarette Solutions',
          description: 'Explore our comprehensive range of cigarette solutions including Homogenized Tobacco Leaf Wrapper, Tipping Paper, and Cigarette Packs.',
          keywords: [
            // English Keywords
            'cigarette paper', 'tipping paper', 'cigarette pack',
            'filter tips', 'individual cigarette pack', 'pre-rolled cones',
            'ryo/myo', 'tobacco processing solutions', 'quality cigarette components',
            // Indonesian Keywords
            'kertas rokok premium', 'kertas tipping berkualitas',
            'kemasan rokok modern', 'filter rokok premium',
            'bahan baku rokok', 'produk rokok berkualitas',
            'pembungkus rokok premium', 'material rokok terbaik',
            'solusi kemasan rokok', 'produk pendukung rokok',
            'bahan kemasan rokok', 'aksesori rokok modern',
            'produk inovasi rokok', 'material rokok terkini'
          ].join(', '),
          image: '/assets/img/business-4/slider/PRODUCT.webp',
          imageAlt: 'Pura MICS Produk Banner',
          type: 'website',
          url: `${this.baseUrl}/ProdukPage`,
          canonical: `${this.baseUrl}/ProdukPage`,
          robotsDirectives: 'index, follow, max-snippet:-1, max-image-preview:large'
        },
        contactus: {
          title: 'Contact Pura MICS - Get in Touch With Us',
          description: 'Contact Pura MICS for innovative cigarette solutions. Reach out to our team for inquiries about our products and services.',
          keywords: [
            // English Keywords
            'pura mics contact', 'cigarette manufacturer contact',
            'tobacco solutions contact', 'manufacturing consultation',
            'business collaboration',
            // Indonesian Keywords
            'kontak produsen rokok', 'konsultasi produk rokok',
            'kerjasama industri rokok', 'layanan konsumen rokok',
            'konsultasi material rokok', 'supplier bahan rokok',
            'distributor produk rokok', 'mitra bisnis rokok'
          ].join(', '),
          type: 'website',
          url: `${this.baseUrl}/ContactUs`,
          canonical: `${this.baseUrl}/ContactUs`,
          robotsDirectives: 'index, follow, noarchive'
        },
        Sampel: {
          title: 'Request Samples - Pura MICS Products',
          description: 'Request samples of our premium cigarette solutions. Experience the quality of Pura MICS products firsthand.',
          keywords: [
            // English Keywords
            'cigarette paper samples', 'tipping paper samples',
            'filter samples', 'packaging samples', 'tobacco product samples',
            // Indonesian Keywords
            'sampel kertas rokok', 'sampel filter rokok',
            'sampel kemasan rokok', 'contoh produk rokok',
            'sampel material rokok', 'sampel bahan rokok',
            'sampel kertas tipping', 'contoh kemasan premium'
          ].join(', '),
          type: 'website',
          url: `${this.baseUrl}/SamplePage`,
          canonical: `${this.baseUrl}/SamplePage`,
          robotsDirectives: 'noindex, nofollow'
        },
        BlogPura: {
          title: 'Pura MICS Blog - Tobacco Industry News, Innovations & Insights',
          description: 'Stay updated with the latest tobacco industry news, product innovations, manufacturing insights, and events from Pura MICS.',
          keywords: [
            // English Keywords
            'tobacco industry news', 'cigarette manufacturing updates',
            'tobacco product innovations', 'industry best practices',
            'manufacturing standards',
            // Indonesian Keywords
            'berita industri rokok', 'inovasi produk rokok',
            'perkembangan industri rokok', 'standar produksi rokok',
            'teknologi rokok terbaru', 'tren industri rokok',
            'update manufaktur rokok', 'artikel industri rokok'
          ].join(', '),
          image: '/assets/img/business-4/slider/produkk.webp',
          imageAlt: 'Pura MICS Blog Banner',
          type: 'website',
          url: `${this.baseUrl}/BlogPura`,
          canonical: `${this.baseUrl}/BlogPura`,
          robotsDirectives: 'index, follow, max-snippet:-1, max-image-preview:large'
        },
        simulasi: {
          title: 'CTP Simulation - Pura MICS Tools',
          description: 'Use our CTP simulation tool to visualize and customize your tobacco product solutions.',
          keywords: [
            // English Keywords
            'CTP simulation', 'tipping paper calculator', 'cigarette design tool',
            // Indonesian Keywords
            'simulasi rokok', 'kalkulator kertas tipping',
            'alat desain rokok', 'simulasi produk rokok',
            'perancangan rokok', 'desain kemasan rokok'
          ].join(', '),
          url: `${this.baseUrl}/simulasiCtp`,
          canonical: `${this.baseUrl}/simulasiCtp`,
          robotsDirectives: 'noindex, follow'
        }
      };

      // Special case for direct path matching (more reliable than route name)
      if (this.$route?.path === '/BlogPura') {
        return configs.BlogPura;
      } else if (this.$route?.path === '/ContactUs') {
        return configs.contactus;
      }

      // Fall back to route name for other pages
      const routeName = this.$route?.name?.toLowerCase() || '';
      return configs[routeName] || configs.home;
    },

    async updateMetaForDetailProduct() {
      try {
        const product = this.product || this.currentProduct;
        if (!product) return;

        const currentLanguage = this.$i18n?.locale || 'en';
        const title = MetaHelpers.formatContent(
          currentLanguage === 'en' ? product.judul_en : product.judul_id
        );
        const description = MetaHelpers.formatContent(
          currentLanguage === 'en' ? 
          (product.deskripsi_en || product.keterangan_en) : 
          (product.deskripsi_id || product.keterangan_id)
        );
        
        const metaInfo = {
          title: `Pura MICS Products - ${title}`,
          description: description || this.defaultDescription,
          keywords: `${product.keyword || ''}, ${this.defaultKeywords}`.trim(),
          image: product.gambar1 ? `${this.baseUrl}/api/produk/file/${product.gambar1}` : null,
          type: 'product'
        };

        this.updateMetaTags(metaInfo);
      } catch (error) {
        console.error('Error updating product meta:', error);
      }
    },

    async updateMetaForDetailBlog() {
      try {
        if (!this.article) return;

        const currentLanguage = this.$i18n?.locale || 'en';
        const title = MetaHelpers.formatContent(
          currentLanguage === 'en' ? this.article.judul_en : this.article.judul_id
        );
        const description = MetaHelpers.formatContent(
          currentLanguage === 'en' ? 
            this.article.deskripsi_en : 
            this.article.deskripsi_id
        );

        const metaInfo = {
          title: title,
          description: description || 'Read our latest article about tobacco industry innovations.',
          keywords: this.article.keyword || this.defaultKeywords,
          image: this.article.gambar1 ? `${this.baseUrl}/api/artikel/file/${this.article.gambar1}` : null,
          type: 'article'
        };

        this.updateMetaTags(metaInfo);
      } catch (error) {
        console.error('Error updating article meta:', error);
      }
    },
    
    // Special methods for specific pages
    forceUpdateBlogMeta() {
      // Only proceed if we're on the BlogPura page
      if (this.$route?.path === '/BlogPura') {
        // Set document title directly to ensure it's applied
        document.title = 'Pura MICS Blog - Tobacco Industry News, Innovations & Insights';
        
        // Get meta config for blog and force update
        const blogConfig = this.getMetaConfig();
        if (blogConfig) {
          this.updateMetaTags(blogConfig);
          
          // Extra safety measure: set title again after a short delay
          setTimeout(() => {
            document.title = 'Pura MICS Blog - Tobacco Industry News, Innovations & Insights';
          }, 100);
        }
      }
    },
    
    forceUpdateContactMeta() {
      // Only proceed if we're on the ContactUs page
      if (this.$route?.path === '/ContactUs') {
        // Set document title directly to ensure it's applied
        document.title = 'Contact Pura MICS - Get in Touch With Us';
        
        // Get meta config for contact and force update
        const contactConfig = this.getMetaConfig();
        if (contactConfig) {
          this.updateMetaTags(contactConfig);
          
          // Extra safety measure: set title again after a short delay
          setTimeout(() => {
            document.title = 'Contact Pura MICS - Get in Touch With Us';
          }, 100);
        }
      }
    }
  },

  watch: {
    '$route': {
      immediate: true,
      handler(newRoute) {
        // Special handling for specific paths
        if (newRoute.path === '/BlogPura') {
          this.forceUpdateBlogMeta();
        } else if (newRoute.path === '/ContactUs') {
          this.forceUpdateContactMeta();
        } else {
          const config = this.getMetaConfig();
          if (config) {
            this.updateMetaTags(config);
          }
        }
      }
    },

    '$i18n.locale'() {
      // Update meta tags when language changes
      if (this.$route?.path === '/BlogPura') {
        this.forceUpdateBlogMeta();
      } else if (this.$route?.path === '/ContactUs') {
        this.forceUpdateContactMeta();
      } else {
        const config = this.getMetaConfig();
        if (config) {
          this.updateMetaTags(config);
        } else if (this.$route?.name === 'DetailProduct') {
          this.updateMetaForDetailProduct();
        } else if (this.$route?.name === 'DetailBlog') {
          this.updateMetaForDetailBlog();
        }
      }
    },

    product: {
      handler(newProduct) {
        if (newProduct && this.$route?.name === 'DetailProduct') {
          this.updateMetaForDetailProduct();
        }
      },
      deep: true
    },

    article: {
      handler(newArticle) {
        if (newArticle && this.$route?.name === 'DetailBlog') {
          this.updateMetaForDetailBlog();
        }
      },
      deep: true
    }
  },

  // Add a mounted hook specifically for BlogPura
  mounted() {
    if (this.$route?.path === '/BlogPura') {
      this.forceUpdateBlogPuraMeta();
      
      // Add a MutationObserver to ensure the title doesn't get changed
      if (typeof MutationObserver !== 'undefined') {
        const titleObserver = new MutationObserver(() => {
          if (document.title !== 'Pura MICS Blog - Tobacco Industry News, Innovations & Insights') {
            document.title = 'Pura MICS Blog - Tobacco Industry News, Innovations & Insights';
          }
        });
        
        titleObserver.observe(document.querySelector('title'), { 
          subtree: true, 
          characterData: true, 
          childList: true 
        });
        
        // Store observer for cleanup
        this._titleObserver = titleObserver;
      }
    }
  },
  
  beforeDestroy() {
    // Clean up observer if it exists
    if (this._titleObserver) {
      this._titleObserver.disconnect();
    }
  }
};