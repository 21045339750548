`<template>
<div class=error-page-wrapper>
<HeaderCom />
<div class=wave-top>
<img src=/assets/img/business-4/not/Assetbawah.avif alt="Wave Top">
</div>
<section class=error-section data-aos=fade-up>
<div class=container>
<div class=error-content>
<div class=text-content>
<h1 class=error-title>404</h1>
<h2 class=error-subtitle>PAGE NOT FOUND</h2>
<p class=error-description>
The page you are looking for might have been removed, changed name, or temporarily unavailable
</p>
<router-link to=/ class=back-button>
Back To Home
</router-link>
</div>
<div class=illustration>
<img src=/assets/img/business-4/not/404.avif alt="404 illustration">
</div>
</div>
</div>
</section>
<div class=wave-bottom>
<img src=/assets/img/business-4/not/Assetatas.avif alt="Wave Bottom">
</div>
</div>
</template>
<script>
import{onMounted as o}from"vue";import t from"aos";import"aos/dist/aos.css";import e from"@/components/HeaderCom.vue";import{metaMixin as m}from"@/mixins/metaMixin";export default{name:"NotFoundPage",components:{HeaderCom:e},mixins:[m],setup(){o(()=>{t.init({duration:800,easing:"ease-in-out",once:!0,offset:0,delay:0,anchorPlacement:"top-bottom"})})}};
</script>
<style scoped>
.error-page-wrapper{min-height:100vh;background-color:#111;position:relative;overflow:hidden}.wave-bottom,.wave-top{position:absolute;z-index:1}.wave-top{top:0;left:0;width:100%}.wave-bottom img,.wave-top img{width:60%;height:auto;display:block}.wave-bottom{bottom:0;right:0;width:100%;display:flex;justify-content:flex-end}.error-section{position:relative;z-index:2;min-height:100vh;display:flex;align-items:center;justify-content:center}.container{max-width:1200px;margin:0 auto;padding:0 1rem}.error-content{display:flex;align-items:center;justify-content:space-between;gap:4rem}.text-content{flex:1;margin-left:70px}.error-title{font-size:8rem;font-weight:700;color:#fdb813;line-height:1;margin-bottom:1rem}.error-subtitle{font-size:2.5rem;color:#fdb813;margin-bottom:1.5rem}.error-description{color:#ccc;font-size:1.1rem;max-width:460px;margin-bottom:2rem;line-height:1.6}.back-button{display:inline-block;background-color:#fdb813;color:#111;padding:12px 16px;border-radius:9999px;text-decoration:none;font-weight:500;transition:background-color .3s}.back-button:hover{background-color:#ffca45}.illustration{flex:0 0 auto;width:300px;margin-top:50px;margin-right:120px}.illustration img{width:100%;height:auto}@media (max-width:768px){.error-content{flex-direction:column;text-align:center;gap:2rem;padding:2rem 1rem}.error-title{font-size:6rem}.error-subtitle{font-size:2rem}.illustration{width:200px}.wave-bottom img,.wave-top img{width:80%}.wave-bottom{position:fixed}.error-description{max-width:100%;padding:0 1rem}}@media (max-width:480px){.wave-bottom img,.wave-top,.wave-top img{width:100%}.back-button{display:inline-block;background-color:#fdb813;color:#111;padding:10px 2rem;border-radius:9999px;text-decoration:none;font-weight:500;transition:background-color .3s}.wave-top{top:70px;left:0}.error-title{font-size:4rem}.error-subtitle{font-size:1.5rem}.illustration{width:210px}}
</style>