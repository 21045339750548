// router/index.js
import { createRouter, createWebHistory, createMemoryHistory } from 'vue-router'
import store from '../store'

// Import main components eagerly
import HomeCom from '../views/HomeCom.vue'
import LoginDs from '../dashboard/LoginDs.vue'
import DashboardDs from '../dashboard/DashboardDs.vue'
import NotFound from '../views/NotFound.vue'

// Preload commonly used components
import BlogPura from '../views/BlogPura.vue'
import AboutPage from '../views/AboutPage.vue'
import ProdukPage from '../views/ProdukPage.vue'
import ContactUs from '../views/ContactUs.vue'

// Helper for editor loading - optimized
const loadEditor = async () => {
    // Skip CKEditor loading on server-side
    if (typeof window === 'undefined') return {}
    
    // Cache the CKEditor components
    if (window.CKEditor && window.ClassicEditor) {
        return { CKEditor: window.CKEditor, ClassicEditor: window.ClassicEditor }
    }
    
    try {
        // Load editor modules in parallel
        const [CKEditor, ClassicEditor] = await Promise.all([
            import('@ckeditor/ckeditor5-vue'),
            import('@ckeditor/ckeditor5-build-classic')
        ])
        
        // Cache for reuse
        window.CKEditor = CKEditor.default
        window.ClassicEditor = ClassicEditor.default
        return { CKEditor: CKEditor.default, ClassicEditor: ClassicEditor.default }
    } catch (error) {
        console.error('Error loading CKEditor:', error)
        return {}
    }
}

export function createServerRouter() {
    return createRouter({
        history: createMemoryHistory(),
        routes: getRoutes()
    })
}

export function createClientRouter() {
    return createRouter({
        history: createWebHistory(process.env.BASE_URL),
        routes: getRoutes(),
        scrollBehavior(to, from, savedPosition) {
            if (savedPosition) {
                return savedPosition
            }
            return {
                top: 0,
                behavior: 'smooth'
            }
        }
    })
}

function getRoutes() {
    return [
        // Public routes - eagerly loaded main components
        {
            path: '/',
            name: 'home',
            component: HomeCom
        },
        {
            path: '/BlogPura',
            name: 'BlogPura',
            component: BlogPura
        },
        {
            path: '/AboutPage',
            name: 'about',
            component: AboutPage
        },
        {
            path: '/ContactUs',
            name: 'contactus',
            component: ContactUs
        },
        {
            path: '/ProdukPage',
            name: 'product',
            component: ProdukPage
        },
        
        // Dynamic routes - still lazy loaded but with better chunking
        {
            path: '/DetailBlog/:slug',
            name: 'DetailBlog',
            component: () => import(/* webpackChunkName: "blog" */ '../views/DetailBlog.vue'),
            props: true
        },
        {
            path: '/DetailProduk/:slug',
            name: 'DetailProduk',
            component: () => import(/* webpackChunkName: "products" */ '../views/DetailProduk.vue'),
            props: true
        },
        {
            path: '/SamplePage',
            name: 'Sampel',
            component: () => import(/* webpackChunkName: "extras" */ '../views/SamplePage.vue')
        },

        // Auth routes
        {
            path: '/LoginDs',
            name: 'LoginDs',
            component: LoginDs,
            meta: { requiresGuest: true }
        },
        {
            path: '/RegisterDs',
            name: 'RegisterDs',
            component: () => import(/* webpackChunkName: "auth" */ '../dashboard/RegisterDs.vue'),
            meta: { requiresAuth: true }
        },

        // Dashboard route - eagerly loaded
        {
            path: '/DashboardDs',
            name: 'DashboardDs',
            component: DashboardDs,
            meta: { requiresAuth: true }
        },

        // Admin routes - grouped by features for better code splitting
        
        // Article module
        {
            path: '/TampilanArtikel',
            name: 'TampilanArtikel',
            component: () => import(/* webpackChunkName: "artikel-admin" */ '../dashboard/TampilanArtikel.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/TambahArtikel',
            name: 'TambahArtikel',
            component: () => import(/* webpackChunkName: "artikel-admin" */ '../dashboard/TambahArtikel.vue'),
            meta: { requiresAuth: true },
            beforeEnter: async (to, from, next) => {
                // Don't block the route while loading
                next()
                // Load editor in background
                loadEditor()
            }
        },
        {
            path: '/EditArtikel/:id_artikel',
            name: 'EditArtikel',
            component: () => import(/* webpackChunkName: "artikel-admin" */ '../dashboard/EditArtikel.vue'),
            props: true,
            meta: { requiresAuth: true },
            beforeEnter: async (to, from, next) => {
                // Don't block the route while loading
                next()
                // Load editor in background
                loadEditor()
            }
        },

        // Product module
        {
            path: '/TampilanProduk',
            name: 'TampilanProduk',
            component: () => import(/* webpackChunkName: "produk-admin" */ '../dashboard/TampilanProduk.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/TambahProduk',
            name: 'TambahProduk',
            component: () => import(/* webpackChunkName: "produk-admin" */ '../dashboard/TambahProduk.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/EditProduk/:id_produk',
            name: 'EditProduk',
            component: () => import(/* webpackChunkName: "produk-admin" */ '../dashboard/EditProduk.vue'),
            props: true,
            meta: { requiresAuth: true }
        },

        // Product detail module
        {
            path: '/TampilanDetailProduk',
            name: 'TampilanDetailProduk',
            component: () => import(/* webpackChunkName: "produk-detail-admin" */ '../dashboard/TampilanDetailProduk.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/TambahDetailProduk',
            name: 'TambahDetailProduk',
            component: () => import(/* webpackChunkName: "produk-detail-admin" */ '../dashboard/TambahDetailProduk.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/EditDetailProduk/:id_detail_produk',
            name: 'EditDetailProduk',
            component: () => import(/* webpackChunkName: "produk-detail-admin" */ '../dashboard/EditDetailProduk.vue'),
            props: true,
            meta: { requiresAuth: true }
        },

        // Category module
        {
            path: '/TampilanKategori',
            name: 'TampilanKategori',
            component: () => import(/* webpackChunkName: "kategori-admin" */ '../dashboard/TampilanKategori.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/TambahKategori',
            name: 'TambahKategori',
            component: () => import(/* webpackChunkName: "kategori-admin" */ '../dashboard/TambahKategori.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/EditKategori/:id_kategori',
            name: 'EditKategori',
            component: () => import(/* webpackChunkName: "kategori-admin" */ '../dashboard/EditKategori.vue'),
            props: true,
            meta: { requiresAuth: true }
        },

        // Users module
        {
            path: '/TampilanUsers',
            name: 'TampilanUsers',
            component: () => import(/* webpackChunkName: "users-admin" */ '../dashboard/TampilanUsers.vue'),
            meta: { requiresAuth: true }
        },

        // Social media module
        {
            path: '/TampilanSosmed',
            name: 'TampilanSosmed',
            component: () => import(/* webpackChunkName: "sosmed-admin" */ '../dashboard/TampilanSosmed.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/TambahSosmed',
            name: 'TambahSosmed',
            component: () => import(/* webpackChunkName: "sosmed-admin" */ '../dashboard/TambahSosmed.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/EditSosmed/:id_sosmed',
            name: 'EditSosmed',
            component: () => import(/* webpackChunkName: "sosmed-admin" */ '../dashboard/EditSosmed.vue'),
            props: true,
            meta: { requiresAuth: true }
        },

        // Additional features module
        {
            path: '/TampilanCtp',
            name: 'TampilanCtp',
            component: () => import(/* webpackChunkName: "extras-admin" */ '../dashboard/TampilanCtp.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/TampilanQuestion',
            name: 'TampilanQuestion',
            component: () => import(/* webpackChunkName: "extras-admin" */ '../dashboard/TampilanQuestion.vue'),
            meta: { requiresAuth: true }
        },

        // Error handling
        {
            path: '/404',
            name: 'NotFound',
            component: NotFound
        },
        {
            path: '/:pathMatch(.*)*',
            redirect: '/404'
        }
    ]
}

// Optimized router guards to reduce redirects
export function setupRouterGuards(router) {
    router.beforeEach((to, from, next) => {
        const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
        const requiresGuest = to.matched.some(record => record.meta.requiresGuest)
        const isLoggedIn = store.getters.isLoggedIn
        
        // Avoid login redirect loop
        const isLoginPage = to.name === 'LoginDs'

        // Save intended route only if not already on login page
        if (requiresAuth && !isLoggedIn && !isLoginPage) {
            store.commit('setIntendedRoute', to.fullPath)
            
            // Use a single redirect
            return next({
                name: 'LoginDs'
            })
        } 
        
        if (requiresGuest && isLoggedIn) {
            return next({ name: 'DashboardDs' })
        }
        
        // Continue navigation
        next()
    })

    return router
}

// Create and export router with optimized initialization
export function createMainRouter(type = 'client') {
    const router = type === 'client' ? createClientRouter() : createServerRouter()
    return setupRouterGuards(router)
}

// Preload common components in background when idle
if (typeof window !== 'undefined') {
    window.addEventListener('load', () => {
        // Use requestIdleCallback if available, otherwise use setTimeout
        const schedulePreload = window.requestIdleCallback || 
            ((cb) => setTimeout(cb, 1000))
            
        schedulePreload(() => {
            // Preload frequently used pages
            import('../views/DetailBlog.vue')
            import('../views/DetailProduk.vue')
        })
    })
}

export default createMainRouter()